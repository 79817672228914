import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import Image from '~components/Image'
import RichText from '~components/RichText'
import { parseISO, format } from 'date-fns'

const ArticleTile = ({ article, className }) => {

	const ratio = article?.thumbnail?.asset?.metadata?.dimensions?.aspectRatio

	const [date, setDate] = useState()

	useEffect(() => {		
		if (!article.publishedAt) return
		const dateString = article.publishedAt
		const date = parseISO(dateString)
		const formattedDate = format(date, 'dd.MM.yy')
		setDate(formattedDate)
	}, [article.publishedAt])
	

	return (
		<Wrap className={className}>
			<StyledLink to={resolveLink(article)}>
				<Thumbnail image={article?.thumbnail} half={ratio < 1}/>
				<Content half={ratio < 1}>
					<Title className='sans'>{article.title}</Title>
					<Date>{date}</Date>
					<Except><RichText content={article?.shortText}/></Except>
					<ReadMore>+Read more</ReadMore>
				</Content>
			</StyledLink>
		</Wrap>
	)
}

const Wrap = styled.div`
	padding-bottom: 60px;
	${mobile}{
		padding-bottom: 40px;
	}
`
const StyledLink = styled(Link)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
`
const Thumbnail = styled(Image)`
	grid-column: ${props => props.half ? 'span 1' : 'span 2'};
	${tablet}{
		grid-column: span 2;
	}
	${mobile}{
		margin-left: -20px;
		margin-right: -20px;
	}
`
const Content = styled.div`
	grid-column: ${props => props.half ? 'span 1' : 'span 2'};
	${tablet}{
		grid-column: span 2;
	}
`
const Title = styled.h2`
	margin-bottom: 6px;
`
const Date = styled.div`
	margin-bottom: 10px;
`
const Except = styled.div`
	
`
const ReadMore = styled.div`
	
`

ArticleTile.propTypes = {
	className: PropTypes.string,
	article: PropTypes.object
}

export default ArticleTile