import React, { useEffect } from 'react'
import { css } from '@emotion/react'
import Seo from '~components/Seo'
import Section from '~components/Section'
import { graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import ArticleTile from '~components/ArticleTile'
import styled from '@emotion/styled'
import { mobile, tablet, InsetSection } from '~styles/global'
import Masonry from 'react-masonry-css'

const NewsPage = ({ data }) => {
	
	const { page, articles } = data

	// useEffect(() => {
	// 	const loadMacy = async () => {
	// 		// Dynamically import Macy library
	// 		const Macy = await import('macy')

	// 		// Initialize Macy
	// 		const macyInstance = Macy.default({
	// 			container: '#macy-container',
	// 			margin: 20,
	// 			columns: 2,
	// 			breakAt: {
	// 				1024: 2,
	// 				768: 1,
	// 			}
	// 		})

	// 		// Recalculate Macy layout
	// 		macyInstance.recalculate()
	// 	}
	// 	loadMacy()
	// }, [])

	const breakpointColumnsObj = {
		default: 2,
		1024: 2,
		768: 1,
	}

	return (
		<>
			<Seo
				title={page?.title}
			/>
			<Wrap>
				<StyledInsetSection>
					<StyledMasonry 
						breakpointCols={breakpointColumnsObj}
						className="m-grid"
						columnClassName="m-grid_column"
					>
						{articles?.nodes?.map((article, index) => (
							<ArticleTile article={article} key={article.slug?.current} />
						))}
					</StyledMasonry>
				</StyledInsetSection>
			</Wrap>
			<style>
				{`
				.m-grid {
					display: -webkit-box; /* Not needed if autoprefixing */
					display: -ms-flexbox; /* Not needed if autoprefixing */
					display: flex;
					width: auto;
				}
				.m-grid_column {
					background-clip: padding-box;
				}
				.m-grid_column:last-of-type {
					padding-left: 20px; /* gutter size */
				}
				@media (max-width: 768px) {
					.m-grid_column:last-of-type {
						padding-left: 0px;
					}
				}
			`}
			</style>
		</>
	)
}

const Wrap = styled.div`
	background-color: var(--white);
	padding-top: 200px;
	padding-bottom: 60px;
`
const StyledMasonry = styled(Masonry)`
	grid-column: span 12;
`
const StyledInsetSection = styled(InsetSection)`
	${mobile}{
		> div {
			padding-left: 20px;
		}
	}
`

export const query = graphql`
  query NewsQuery {
    page: sanityNews {
      title
			seo{
				...seo
			}
    }
		articles: allSanityArticle {
			nodes {
				_type
				title
				slug {
					current
				}
				thumbnail{
					...imageWithAlt
					asset {
          metadata {
            dimensions {
              aspectRatio
            }
          }
        }
				}
				mainImage{
					...imageWithAlt
				}
				publishedAt
				shortformTitle
				shortText: _rawShortText(resolveReferences: {maxDepth: 5})
				content: _rawContent(resolveReferences: {maxDepth: 5})
			}
		}
  }
`
NewsPage.propTyes = {
	data: PropTypes.object,
	pageContext: PropTypes.object
}

export default NewsPage
